import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, IconButton, Typography, Box, Chip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { editUser } from '../../services/user/user';
import { getTeacherSubjectSubscription } from '../../services/teacher subcription/teacherSubcription';
import Avatar from '@mui/material/Avatar';

function TeacherDetails(props) {
  const { teacher = {}, onSave, onClose, open, loadTeachers,teacherId } = props;

  const [qualification, setQualification] = useState('');
  const [description, setDescription] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [subscriptions,setSubscriptions]= useState([]);
  const [profileURL,setProfileURL]= useState("")
  const [newProfileURL,setNewProfileURL]= useState("")
  useEffect(() => {
    if (teacher.myTeachers && teacher.myTeachers[0]) {
      setQualification(teacher.myTeachers[0].qualification);
      setDescription(teacher.myTeachers[0].description);
      setShortDescription(teacher.myTeachers[0].shortDescription);
    }
    setProfileURL(teacher.profileurl)
    setNewProfileURL(teacher.newProfileUrl)
    setIsApproved(teacher.isApproved);
  }, [teacher]);

  const handleSave = async () => {
    const editDetails = {
      profileurl: profileURL, 
      newProfileUrl:newProfileURL,
      myTeachers: [
        {
          qualification: qualification,
          shortDescription: shortDescription,
          description: description,
        },
      ],
    };
    try {
      const response = await editUser(teacher.id, editDetails);
      if (response.status === 200) {
        setIsEditing(false);
        const updatedTeacher = { ...teacher, myTeachers: [{ qualification, shortDescription, description }],profileUrl:newProfileURL ,newProfileURL:""};
        onSave(updatedTeacher);  
        loadTeachers();
        handleClose();  
      } else {
        console.error('Error saving details:', response.message || 'Unknown error');
      }
    } catch (error) {
      console.error('Error saving details:', error);
 
    }
  };
  

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleApprove = async () => {
    const approveTeacher = { isApproved: true };
    try {
      const response = await editUser(teacher.id, approveTeacher);
      if (response.success) {
        setIsApproved(true);
        onSave({ ...teacher, isApproved: true });
        loadTeachers();
      }
    } catch (error) {
      console.error('Error approving teacher:', error);
    }
  };

  const handleReject = async () => {
    const rejectTeacher = { isApproved: false };
    try {
      const response = await editUser(teacher.id, rejectTeacher);
      if (response.success) {
        setIsApproved(false);
        onSave({ ...teacher, isApproved: false });
        loadTeachers();
      }
    } catch (error) {
      console.error('Error rejecting teacher:', error);
    }
  };

  const handleClose = () => {
    if (isEditing) {
      setIsEditing(false);
    } else {
      onClose();
    }
  };

  const loadSubjectSubscription = async()=>{
    try{
      const response = await getTeacherSubjectSubscription(teacherId)
      const {data = []} = response
      setSubscriptions(data)
    }catch(error){
      console.log(error)
    }
  };

  useEffect(()=>{
    loadSubjectSubscription();
  },[teacherId])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{ '.MuiDialog-paper': isEditing ? {} : { minWidth: '600px', minHeight: '400px' } }}
    >
      <DialogTitle>
        Teacher Info
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {isEditing ? (
          <>
            <div style={{display:"flex"}}> 
              <Avatar
                alt="Old profile"
                src ={profileURL}
                sx={{ width: 96, height: 96 ,margin:"10px"}}
              >
              </Avatar>
              <Avatar
                alt="New profile"
                src ={newProfileURL}
                sx={{ width: 96, height: 96 ,margin:"10px"}}
              >
              </Avatar>
            </div> 
            <TextField
              autoFocus
              margin="dense"
              label="Qualification"
              type="text"
              fullWidth
              variant="outlined"
              value={qualification}
              onChange={(e) => setQualification(e.target.value)}
            />
            <TextField
              margin="dense"
              label="Description"
              type="text"
              fullWidth
              variant="outlined"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <TextField
              margin="dense"
              label="Short Description"
              type="text"
              fullWidth
              variant="outlined"
              value={shortDescription}
              onChange={(e) => setShortDescription(e.target.value)}
            />
          </>
        ) : (
          <>
            <div style={{display:"flex"}}> 
              <Avatar
                alt="Old profile"
                src ={profileURL}
                sx={{ width: 96, height: 96 , margin:"10px"}}
              >
              </Avatar>
              <Avatar
                alt="New profile"
                src ={newProfileURL}
                sx={{ width: 96, height: 96 ,margin:"10px"}}
              >
              </Avatar>
            </div> 
            <Box mb={2} sx={{ border: '1px solid #ccc', borderRadius: '4px', padding: '8px' }}>
              <Typography variant="h6">Qualification:</Typography>
              <Typography variant="body1">{qualification}</Typography>
            </Box>
            <Box mb={2} sx={{ border: '1px solid #ccc', borderRadius: '4px', padding: '8px' }}>
              <Typography variant="h6">Description:</Typography>
              <Typography variant="body1">{description}</Typography>
            </Box>
            <Box mb={2} sx={{ border: '1px solid #ccc', borderRadius: '4px', padding: '8px' }}>
              <Typography variant="h6">Short Description:</Typography>
              <Typography variant="body1">{shortDescription}</Typography>
            </Box>
            <Box mb={2} sx={{ border: '1px solid #ccc', borderRadius: '4px', padding: '8px' }}>
              <Typography variant="h6">Subjects:</Typography>
              {subscriptions.map(subscription => (
                <Chip key={subscription.id} label={subscription.subject.name} />
              ))}
        </Box>
          </>
        )}
      </DialogContent>
      <DialogActions>
        {isEditing ? (
          <>
            <Button onClick={handleSave} color="primary" variant="contained">
              Save
            </Button>
            <Button onClick={handleClose} color="secondary" variant="contained">
              Cancel
            </Button>
          </>
        ) : (
          <>
            <Button onClick={handleEdit} color="primary" variant="contained">
              Edit
            </Button>
            {isApproved ? (
              <Button onClick={handleReject} color="error" variant="contained">
                Reject
              </Button>
            ) : (
              <Button onClick={handleApprove} color="secondary" variant="contained">
                Approve
              </Button>
            )}
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default TeacherDetails;
